<template>
  <div class="container-fluid mt-3 profile-wrapper">
    <form @submit="handleChangePassword">
      <div class="row my-5">
        <div class="col-lg-5 mx-auto">
          <el-card class="h-100 pb-4">
            <div class="d-flex mb-4 border-bottom border-bottom justify-content-between align-items-center flex-wrap">
              <Breadcrumb current_page="تغيير كلمة المرور"/>

            </div>


            <div class="form-group">
              <label for="old_password">كلمة المرور الحالية</label>
              <input type="password" v-model="old_password" name="old_password" autocomplete="old_password" id="old_password" class="form-control">
              <span class="text-danger">{{ passwordError }}</span>
            </div>
            <div class="form-group mt-3">
              <label for="new-password">كلمة المرور الجديدة</label>
              <input type="password" v-model="new_password" name="new_password" autocomplete="new-password" id="new-password" class="form-control">
              <span class="text-danger">{{ new_passwordError }}</span>
            </div>
            <div class="form-group mt-3">
              <label for="confirmation-password">تأكيد كلمة المرور</label>
              <input type="password" v-model="new_password_confirmation" name="new_password_confirmation" autocomplete="confirmation-password"  id="confirmation-password" class="form-control">
              <span class="text-danger">{{ new_password_confirmationError }}</span>
            </div>

            <div class="controls-btns mt-4 mb-2">
              <button class="btn btn-publish" type="submit" :disabled="isSubmitting">حفظ</button>
            </div>
          </el-card>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { useField, useForm} from "vee-validate";
import Breadcrumb from "@/components/Breadcrumb";
import {useToast} from "vue-toastification";
import {changePassword} from "@/services/auth";

const toast = useToast()

const schema = {
  old_password: 'required',
  new_password: 'required',
  new_password_confirmation: 'required|confirmed:new_password'
};

const {handleSubmit,isSubmitting} = useForm({validationSchema:schema})

const { value: old_password, errorMessage: passwordError } = useField('old_password');
const { value: new_password, errorMessage: new_passwordError } = useField('new_password');
const { value: new_password_confirmation, errorMessage: new_password_confirmationError } = useField('new_password_confirmation');

const handleChangePassword =  handleSubmit( async (values,{ resetForm }) =>{
  try {
    console.log(values)
    const result = await changePassword(values)
    toast.success('تم الحفظ بنجاح')
    resetForm()
  }catch (e) {
    console.log(e.message)
    toast.error('الرجاء التأكد من البيانات المدخلة والمحاولة مرة أخرى')
  }
})
</script>


<style scoped>
.form-control {
  width: 100%;
}
</style>
